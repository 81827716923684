<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card>
          <v-card-title>
            <h2>Generate Token</h2>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field
                label="Meeting Room Code"
                :prefix="meetingBaseUrl"
                v-model="room"
                :disabled="isAllRoom"
                outlined
                dense
              >
                <template #append-outer>
                  <v-checkbox
                    v-model="isAllRoom"
                    label="All Room"
                    class="ma-0 pt-0"
                  ></v-checkbox>
                </template>
              </v-text-field>
              <v-text-field
                label="Name"
                v-model="name"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="email"
                outlined
                dense
              ></v-text-field>
              <v-select
                label="Affiliation"
                outlined
                dense
                v-model="affiliation"
                :items="affiliationOptions"
              ></v-select>
              <v-btn color="primary" @click="generateToken"
                >Generate Token</v-btn
              >
            </v-form>
          </v-card-text>
          <v-row class="pa-4">
            <v-col cols="12">
              <v-card-text
                >Token
                <v-btn icon x-small @click="$copyToClipboard(generatedToken)">
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn></v-card-text
              >
              <v-textarea readonly solo rows="2" v-model="generatedToken">
              </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-card-text
                >Meeting Link
                <v-btn
                  icon
                  x-small
                  @click="$copyToClipboard(generatedMeetingLink)"
                >
                  <v-icon> mdi-content-copy </v-icon>
                </v-btn>
              </v-card-text>
              <v-textarea readonly v-model="generatedMeetingLink" solo rows="3">
              </v-textarea>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isAllRoom: false,
      room: "",
      name: "",
      email: "",
      affiliation: "member",
      affiliationOptions: ["owner", "member"],
      generatedToken: "",
      generatedMeetingLink: "",
    };
  },
  computed: {
    meetingBaseUrl() {
      // return process.env.VUE_APP_JITSI_BASEURL
      //   ? process.env.VUE_APP_JITSI_BASEURL.replace("https://") + "/"
      //   : "s101.meetingme.live/";
      return "https://workshop.meetingme.live/meeting/".replace("https://", "");
    },
  },
  methods: {
    generateToken() {
      this.$axios
        .post("/meetings/access-token/", {
          room: this.isAllRoom ? "*" : this.room,
          name: this.name,
          email: this.email,
          affiliation: this.affiliation,
        })
        .then((res) => {
          this.generatedToken = res.data.token;
          this.generatedMeetingLink = res.data.meeting_link.replace(
            "https://s101.meetingme.live/",
            "https://workshop.meetingme.live/meeting/"
          );
        });
    },
  },
};
</script>

<style>
</style>